import styled from 'styled-components';

import { GridToolbarContainer } from '@mui/x-data-grid';

export const BarraEstilizada = styled(GridToolbarContainer)`
  && {
    button {
      margin-left: 4px;

      &:hover {
        background-color: rgba(25, 118, 210, 0.06);
      }
    }
  }
`;
