import React, {
  useCallback,
  useContext,
  useState,
  useRef,
} from 'react';

import type { Perfil } from '../../../../../../api/obtem/ObtemListaDePerfis/tipos';

import {
  ContextoDeDados,
  ContextoDeVisualizacao,
} from '../../../../../../contextos';

import { Autocompletar } from '../../../../../';

import type { SetState } from '../../../../../../util/tipos';

const TIPO = 'pessoas-por-perfil';

function FormularioPessoaPorPerfil() {
  const { obterListaDePerfis } = useContext(ContextoDeDados);
  const { obterIndicesDePessoas } = useContext(ContextoDeVisualizacao);

  /**
   * Estado de controle do valor do autocomplete.
   */
  const [valorSelecionado, defValorSelecionado] = useState<Perfil | null>(null);

  /**
   * Estado que define se o componente está carregando.
   */
  const [carregando, defCarregando] = useState<boolean>(false);

  /**
   * Referencia que monitora o id de timeout da requisição ao digitar.
   */
  const refRequisicao = useRef(-1);

  /**
   * Callback chamado ao digitar no Autocompletar
   */
  const aoDigitar = useCallback((valor: string, defOpcoes: SetState<Perfil[]>) => {
    window.clearTimeout(refRequisicao.current);
    refRequisicao.current = window.setTimeout(() => {
      defCarregando(true);
      obterListaDePerfis({ nome: valor })
        .then(resultado => defOpcoes(resultado))
        .catch(erro => console.error(erro))
        .finally(() => {
          defCarregando(false);
        });
    }, 800);
  }, []);

  /**
   * Callback chamado ao selecionar uma opção do Autocompletar.
   */
  const aoSelecionar = useCallback((valor: Perfil | null) => {
    defValorSelecionado(valor);
    if (valor) obterIndicesDePessoas({ tipo: TIPO, perfil: valor.idPerfil.toString() });
  }, []);

  /**
   * Callback no inciocio da renderização para carregar a lista inicial de perfis.
   */
  const carregarListaDeOpcoes = useCallback((defOpcoes: SetState<Perfil[]>) => {
    defCarregando(true);
    obterListaDePerfis({ nome: '' })
      .then(resultado => defOpcoes(resultado))
      .catch(erro => console.error(erro))
      .finally(() => {
        defCarregando(false);
      });
  }, []);

  return (
    <Autocompletar
      {...{
        aoDigitar,
        aoSelecionar,
        carregarListaDeOpcoes,
        carregando,
      }}
      placeholder="Selecione um perfil"
      semOpcoes="Nenhum perfil encontrado"
      propRotuloDeOpcao="apelido"
      valor={valorSelecionado}
    />
  );
}

/**
 * Componente Autocompletar para seleção de um perfil.
 */
export default FormularioPessoaPorPerfil;