import React, {
  memo,
  useContext,
} from 'react';

import Typography from '@mui/material/Typography';

import Icone from './componentes/Icone';

import type { Pessoa } from '../../../../../../../../../../api/obtem/ObtemPessoa/tipos';

import { ContextoDeVisualizacao } from '../../../../../../../../../../contextos';

import { Conteudo } from '../../estilos';

interface PropsDetalhesPessoas {
  dados: Pessoa;
}


function DetalhesPessoas({ dados }: PropsDetalhesPessoas) {
  const { AbrirCadastroAppVoluntario } = useContext(ContextoDeVisualizacao);

  return (
    <>
      <Conteudo className="DialogoDeDetalhes-conteudo DetalhesPessoas">
        <Icone
          cor={dados.cor}
          foto={dados.foto}
        />
        <div>
          <Typography
            style={{
              fontSize: '1rem',
              fontWeight: 'bold',
            }}
          >
            {dados.nome}
          </Typography>
          <Typography
            style={{
              padding: '2px 2px 6px',
              color: '#999',
              fontSize: '0.7rem',
              fontWeight: 600,
            }}
          >
            {dados.apelido}
          </Typography>
        </div>
        <Typography
          style={{
            fontSize: '1rem',
            fontWeight: 'bold',
          }}
        >
          {dados.cidade}/{dados.estado}
        </Typography>
      </Conteudo>
      <AbrirCadastroAppVoluntario idPessoa={dados.idPessoa} />
    </>
  );
}

/**
 * Conteudo do `<DialogoDeDetalhes>` quando uma pessoa é selecionado.
 */
export default memo(DetalhesPessoas);
