import React from 'react';

import Typography from '@mui/material/Typography';

import { Corpo, BlocoDeTexto } from './styles';

interface PropsSessaoNaoAutorizada {
  validacao?: false
}

function SessaoNaoAutorizada({ validacao }: PropsSessaoNaoAutorizada) {
  return (
    <Corpo>
      {
        validacao !== undefined && (
          <BlocoDeTexto>
            <Typography
              style={{
                fontWeight: 'bold',
                fontSize: 18,
              }}
            >
              Sessão não autorizada
            </Typography>
            <Typography
              style={{
                fontSize: 16,
                textAlign: 'center',
                marginTop: 20,
              }}
            >
              Verifique se você possui autorização para utilizar esse módulo<br />
              e recarregue a página novamente
            </Typography>
          </BlocoDeTexto>
        )
      }
    </Corpo>
  );
}

/**
 * Componente de aviso de falta de autorização.
 */
export default SessaoNaoAutorizada;
