import Obtem from '../Obtem';

import type { DadosIndice } from './tipos';

type TiposDeDados = 'pessoa';

/**
 * Classe abstrata para implementaçao dos diferentes tipos
 * de parametros para o endpoint GET /indices
 *
 * Implementa a rota do endpoint GET /indices.
 * Define a variavel abstrata `tipoDeDado` a ser implementado pelas subclasses.
 */
export default abstract class ObtemIndices<T> extends Obtem<DadosIndice<T>[]> {
  protected readonly URLBase: string = process.env.REACT_APP_API_BI ?? ''; // eslint-disable-line
  protected readonly rota: string;

  /**
   * Tipo de dado a ser buscado.
   * Implementado em subclasses.
   */
  protected readonly abstract tipoDeDado: TiposDeDados;

  constructor(token: string, filtros: URLSearchParams) {
    super(token);
    this.rota = this.definirRota(filtros);
  }

  protected definirRota(parametros: URLSearchParams): string {
    const parametrosDeBusca = parametros?.toString();
    return this.URLBase + `/indices?${parametrosDeBusca}`;
  }

  public async resultado(): Promise<DadosIndice<T>[]> {
    try {
      const { data, status } = await this.conexao.get(this.rota);
      if (status === 200) {
        return data.map((item) => ({
          indice: item['indice_posicao'],
          rotulo: item.rotulo,
          dados: item[this.tipoDeDado],
        }));
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
}