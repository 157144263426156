import Conexao from '../Conexao';

/**
 * Classe base das requisições de busca de dados (GET).
 * Define o método abstrato `resultado()`
 */
export default abstract class Obtem<T> extends Conexao<T> {
  /**
   * Método implementado pelas subclasses para trazer os dados
   * de um endpoint da API.
   */
  public abstract resultado(): Promise<T>;
}
