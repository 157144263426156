/**
 * Classe abstrata para controle de `localStorage` e `sessionStorage`
 */
export default abstract class Armazenamento<T> {
  protected static PREFIXO: 'app-basometro' = 'app-basometro';
  protected chave: string;
  protected abstract item: string;

  constructor(chave: string) {
    this.chave = `${Armazenamento.PREFIXO}:${chave}`;
  }

  existe(): boolean {
    return this.item !== '';
  }

  obter(): T {
    return JSON.parse(this.item);
  }

  abstract salvar(valor: T): void;
}
