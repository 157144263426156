import React, {
  memo,
} from 'react';

import { Conteudo } from './estilos';

function TelaVazia() {
  return <Conteudo>Selecione um filtro para visualização</Conteudo>;
}

/**
 * Tela de visualização para quando não há dados carregados.
 */
export default memo(TelaVazia);