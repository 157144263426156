import Conexao from '../Conexao';

/**
 * Classe base das requisições de manipulação de dados (POST/PUT...).
 * Define o método abstrato `executar()`.
 */
export default abstract class Manipula<T> extends Conexao<T> {
  /**
   * Método implementado pelas subclasses para manipular dados
   * através de um endpoint da API.
   */
  public abstract executar(): Promise<T>;
}
