import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import TextField from '@mui/material/TextField';
import type {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from '@mui/material/Autocomplete';

import { AutocompletarEstilizado } from './estilos';

import type {
  Estilizacao,
  SetState,
} from '../../util/tipos';

interface AoMudarValor<T extends 'input' | 'opcao', S> {
  (
    e: React.SyntheticEvent,
    valor: T extends 'input' ? string : (S | null),
    motivo: T extends 'input' ? AutocompleteInputChangeReason : AutocompleteChangeReason,
    acao: T extends 'input' ? ((valor: string, defOpcoes: SetState<S[]>) => void) : ((valor: S) => void)
  ): void;
}

interface PropsAutocompletar<T> {
  /** Procedimento realizado ao digitar no componente Autocompletar */
  aoDigitar: ((valor: string, defOpcoes: SetState<T[]>) => void);
  /** Procedimento realizado ao selecionar uma das opções dadas pelo componente Autocompletar */
  aoSelecionar: ((valor: T | null) => void);
  /** Booleano que define que o componente está carregando opções */
  carregando?: boolean;
  /** Procedimento que carrega as opções da lista na primeira renderização */
  carregarListaDeOpcoes: (defOpcoes: SetState<T[]>) => void;
  /** Estilização através de `styled-components`. */
  estilos?: Estilizacao;
  /** Rotulo que renderiza enquanto nenhuma opção foi selecionada */
  placeholder?: string;
  /**
   * Nome da propriedade que define a opção na lista de opções.
   * **Apenas para listas de objetos**
   * 
   * Ex: Para `{ nome: string, id: number }[]` - poderia passar a string `'nome'`
   * 
   * Para lista de opções que seja `string[]`, deve ser `undefined`.
   */
  propRotuloDeOpcao?: string;
  /** Texto que renderiza quando não há opções selecionáveis */
  semOpcoes?: string;
  /** Valor de controle do componente Autocompletar */
  valor: T | null;
}

function Autocompletar<T>(props: PropsAutocompletar<T>) {
  /**
   * Estado de armazenamento de opções.
   */
  const [opcoes, defOpcoes] = useState<T[]>([]);

  /**
   * Função chamada ao digitar no componente.
   * OBS: Possivelmente adicionar implementações para diferentes valores de `motivo`.
   *   -> 'input' | 'reset' | 'clear'
   */
  const aoDigitar = useCallback<AoMudarValor<'input', T>>((e, valor, motivo, acao) => {
    if (motivo === 'reset') return;
    else acao(valor, defOpcoes);
  }, []);

  /**
   * Função chamada ao selecionar uma opção.
   * OBS: Possivelmente adicionar implementações para diferentes valores de `motivo`.
   *   -> 'createOption' | 'selectOption' | 'removeOption' | 'clear' | 'blur'
   */
  const aoSelecionar = useCallback<AoMudarValor<'opcao', T>>((e, valor, motivo, acao) => {
    if (motivo !== 'selectOption') return;
    else if (valor) acao(valor);
  }, []);

  /**
   * Esse efeito deve carregar as opções do autocomplete na primeira renderização.
   */
  useEffect(() => {
    props.carregarListaDeOpcoes(defOpcoes);
  }, [props.carregarListaDeOpcoes]);

  return (
    <AutocompletarEstilizado
      noOptionsText={props.semOpcoes ?? 'Nenhuma opção encontrada'}
      loading={props.carregando}
      loadingText="Carregando..."
      estilos={props.estilos}
      // @ts-expect-error `opcao` com tipo desconhecido
      getOptionLabel={opcao => props.propRotuloDeOpcao ? opcao[props.propRotuloDeOpcao] : opcao}
      // @ts-expect-error `valor` com tipo desconhecido
      onChange={(e, valor, motivo) => aoSelecionar(e, valor, motivo, props.aoSelecionar)}
      onInputChange={(...args) => aoDigitar(...args, props.aoDigitar)}
      options={opcoes}
      renderInput={(params) =>
        <TextField
          {...params}
          label={props.placeholder ?? 'Selecione uma opção'}
          variant="filled"
        />
      }
      value={props.valor}
      blurOnSelect
    />
  );
}

/**
 * Componente genérico de Autocompletar.
 * Possui uma estilização padrão por `styled-components`.
 * 
 * Elementos podem receber mais estilizações pela prop `estilos`,
 * utilizando a sintaxe do `styled-components`.
 */
export default Autocompletar;
