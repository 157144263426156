import styled from 'styled-components';

import Badge from '@mui/material/Badge';

interface PropsEmblema {
  cor: string;
}

export const Emblema = styled(Badge)<PropsEmblema>`
  && {
    justify-content: center;
    padding: 0 0 8px;

    & > .MuiAvatar-root {
      width: 110px;
      height: 110px;
    }

    & > .MuiBadge-badge {
      width: 21px;
      height: 21px;

      border: solid 3px #fff;
      border-radius: 50%;
      background-color: ${({ cor }) => cor};

      translate: -24px -2px;
    }
  }
`;
