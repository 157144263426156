import React, {
  useContext,
  useMemo,
} from 'react';

import { ContextoDeVisualizacao } from '../../contextos';

// import FormularioPeriodo from './componentes/FormularioPeriodo';
import ModoDeVisualizacao from './componentes/ModoDeVisualizacao';

import {
  NumeroDeIndices,
  RodapeEstilizado,
} from './estilos';

function Rodape() {
  const { indices, status } = useContext(ContextoDeVisualizacao);

  const numeroDeIndices = useMemo(() => (
    indices.length.toLocaleString('pt-BR') + ' ' + status
  ), [indices.length, status]);

  return (
    <RodapeEstilizado>
      {/* <FormularioPeriodo /> */}
      <ModoDeVisualizacao />
      {
        status !== 'carregando'
        && status !== 'sem-dados' && (
          <NumeroDeIndices>
            {numeroDeIndices} encontradas
          </NumeroDeIndices>
        )
      }
    </RodapeEstilizado>
  );
}

export default Rodape;