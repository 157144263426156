import styled from 'styled-components';

export const LogoEstilizado = styled.div`
  && {
    color: #1FB9FB;
    font-weight: bold;

    @media screen and (max-width: 480px) {
      margin-bottom: 8px;
      font-size: 13px;
    }
  }
`;