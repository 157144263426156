import Obtem from '../Obtem';

import type { DadosCor } from '../../../util/tipos';

/**
 * Classe para requisição GET /cores da `api-pessoas`.
 * Retorna uma lista com as cores do sistema.
 */
export default class ObtemCores extends Obtem<DadosCor[] | undefined> {
  protected readonly URLBase = process.env.REACT_APP_API_PESSOAS ?? ''; // eslint-disable-line
  protected readonly rota: string;

  constructor(token: string) {
    super(token);
    this.rota = this.definirRota();
  }

  protected definirRota(): string {
    return this.URLBase + '/cores';
  }

  public async resultado(): Promise<DadosCor[] | undefined> {
    try {
      const { data, status } = await this.conexao.get<DadosCor[]>(this.rota);
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return undefined;
  }
}