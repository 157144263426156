import React from 'react';
import ReactDOM from 'react-dom';

import Rotas from './paginas';
import EstilosGlobais from './estilos';

import { ProvedorDoApp } from './contextos/ContextoDoApp';
import { ProvedorDeDados } from './contextos/ContextoDeDados';

document.title = 'Basômetro';

ReactDOM.render(
  <ProvedorDoApp>
    <ProvedorDeDados>
      <Rotas />
      <EstilosGlobais />
    </ProvedorDeDados>
  </ProvedorDoApp>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
