import Obtem from '../Obtem';

import type {
  Pessoa,
  PessoaDaApi,
} from './tipos';

/**
 * Classe de requisição para GET /pessoas da `api-pessoas`.
 * Retorna um objeto com informações de uma dada pessoa.
 */
export default class ObtemPessoa extends Obtem<Pessoa | undefined> {
  protected readonly URLBase = process.env.REACT_APP_API_PESSOAS ?? ''; // eslint-disable-line
  protected readonly rota: string;

  private readonly idPessoa: number;

  constructor(token: string, idPessoa: number) {
    super(token);
    this.idPessoa = idPessoa;
    this.rota = this.definirRota();
  }

  protected definirRota(): string {
    return this.URLBase + `/pessoas/${this.idPessoa}`;
  }

  public async resultado(): Promise<Pessoa | undefined> {
    try {
      const { data, status } = await this.conexao.get<PessoaDaApi>(this.rota);
      if (status === 200) {
        return {
          apelido: data.apelido,
          cidade: data.cidade,
          cor: data.corhexa,
          estado: data.estado,
          foto: data.foto,
          idPessoa: data.idpessoa,
          nome: data.nome,
        };
      }
    } catch (error) {
      console.error(error);
    }
    return undefined;
  }
}