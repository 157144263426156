import styled from 'styled-components';

export const AreaFlutuante = styled.div`
  && {
    background-color: #18262F;
    border-radius: 8px;
    position: relative;
    margin: 0 15px;
    box-shadow: 0 0 15px #000;
  }
`;