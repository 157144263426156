import axios from 'axios';
import type {
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';

/**
 * Classe base de todas a requisições.
 * Implementa o uso do `axios` e define o método abstrato `definirRota()`
 */
export default abstract class Conexao<T> {
  /**
   * Controle do axios.
   */
  protected readonly conexao: AxiosInstance;
  /**
   * Rota de uma requisição dado a URL base.
   */
  protected abstract readonly rota: string;
  /**
   * URL base especifica para cada API.
   */
  protected abstract readonly URLBase: string;
  /**
   * Método para definição da rota de um endpoint.
   * Implementado pelas subclasses.
   */
  protected abstract definirRota(parametros?: URLSearchParams): string;

  /**
   * TODO: O app precisara de token de autorização.
   *    -> Passar autorizaçao no header
   */
  constructor(token?: string) {
    const config: AxiosRequestConfig<T> | undefined = token
      ? { headers: { Authorization: token } }
      : undefined;
    this.conexao = axios.create(config);
  }
}
