import React from 'react';
import styled from 'styled-components';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface PropsMarcador {
  x: number;
  y: number;
}

const MarcadorAnimado = styled(ArrowDropDownIcon)`
  @keyframes bounce {
    from { transform: translateY(0.2px); }
    to { transform: translateY(-1.2px); }
  }

  && {
    color: #fff;
    display: none;
    animation: bounce .75s linear infinite alternate paused
  }
`;

function Marcador(props: PropsMarcador) {
  return (
    <MarcadorAnimado
      width={25}
      height={25}
      x={props.x}
      y={props.y}
    />
  );
}

/**
 * Marcador animado do componente `<Ponto>`
 */
export default Marcador;