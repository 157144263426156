import Manipula from './Manipula';

/**
 * Classe para validação do token de sessão.
 */
export default class ValidaTokenDeSessao extends Manipula<boolean> {
  protected readonly URLBase = process.env.REACT_APP_API_BI ?? ''; // eslint-disable-line
  protected readonly rota: string;

  private readonly token: string;

  constructor(token: string) {
    super();
    this.token = token;
    this.rota = this.definirRota();
  }

  protected definirRota(): string {
    return this.URLBase + `/auth/sessao/${this.token}`;
  }

  public async executar(): Promise<boolean> {
    try {
      const { status } = await this.conexao.post(this.rota);
      if (status === 200) return true;
    } catch (erro) {
      console.error(erro);
    }
    return false;
  }

}