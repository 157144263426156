import styled from 'styled-components';

import Autocomplete from '@mui/material/Autocomplete';

import type { Estilizacao } from '../../util/tipos';

interface PropsAutocompletarEstilizado {
  estilos?: Estilizacao;
}

export const AutocompletarEstilizado = styled(Autocomplete)<PropsAutocompletarEstilizado>`
  && {    
    width: 100%;
    
    .MuiAutocomplete-input {
      padding: 7px 10px;
    }
    
    .MuiAutocomplete-inputRoot {
      background-color: #fff;
      padding-top: 0;
      margin: 0;
      border-radius: 4px;
    }

    .MuiInputLabel-filled {
      top: -9px;

      &.MuiInputLabel-shrink {
        top: 2px;
      }

      &.MuiInputLabel-shrink.MuiInputLabel-filled {
        display: none;
      }
    }

  ${({ estilos }) => estilos ? (
    Object.entries(estilos).reduce((total, [regra, propriedades]) => (
      total
        + regra
        + JSON.stringify(propriedades)
          .replace(/,/g, ';')
          .replace(/"/g, '')
        + '\n'
    ), '')
  ) : ''}
  }
`;