import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import { ContextoDoApp } from '../../contextos';

import Basometro from './componentes/Basometro';
import SessaoNaoAutorizada from './componentes/SessaoNaoAutorizada';

function Inicial() {
  const { validarTokenDeSessao } = useContext(ContextoDoApp);

  /**
   * Estado que monitora a validação da sessão.
   */
  const [sessaoValidada, defSessaoValidada] = useState<boolean | undefined>(undefined);

  /**
   * Efeito que realiza a validação da sessão atual
   * no inicio da aplicação.
   */
  useEffect(() => {
    validarTokenDeSessao()
      .then(validado => defSessaoValidada(validado))
      .catch(erro => {
        console.error(erro);
        defSessaoValidada(false);
      });
  }, [validarTokenDeSessao]);

  return (
    <>
      {
        sessaoValidada
          ? <Basometro />
          : <SessaoNaoAutorizada validacao={sessaoValidada} />
      }
    </>
  );
}

/**
 * Página inicial do app.
 */
export default Inicial;
