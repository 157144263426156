import React from 'react';

import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

import Inicial from './Inicial';

function Rotas() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<Inicial />}
        />
        {/* Adicionar mais rotas caso o App possua */}
      </Routes>
    </BrowserRouter>
  );
}

/**
 * Componente de definição de rotas do app.
 */
export default Rotas;
