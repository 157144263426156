import styled from 'styled-components';

export const Corpo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #414A56;
  width: 100vw;
  height: 100vh;
`;

export const BlocoDeTexto = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
`;