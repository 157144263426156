import React from 'react';
import type { DOMAttributes } from 'react';

import {
  Path,
  Point,
  VictoryLabel,
} from 'victory';
import type { PointProps } from 'victory';

import { Indices } from '../../../../api/obtem/ObtemIndices/tipos';

import Marcador from './componentes/Marcador';

type EventosDoPonto = Omit<DOMAttributes<SVGPathElement | SVGGElement>, 'children' | 'dangerouslySetInnerHTML'>;
export interface PropsPonto extends PointProps {
  datum?: Indices & { _x: number; _y: number; };
  events?: EventosDoPonto;
}

function Ponto(props: PropsPonto) {
  const { x = 0, y = 0 } = props;
  const {
    onClick,
    onMouseEnter,
    onMouseLeave,
  } = props.events ?? ({} as EventosDoPonto);

  return (
    <g
      id={`pontos-dados-${props.index}`}
      className="pontos-dados"
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ cursor: 'pointer' }}
    >
      <Marcador
        x={x - 12.5}
        y={y - 20}
      />
      <Point
        {...props}
        pathComponent={<Path style={props.style} />}
        size={3.5}
      />
      <VictoryLabel
        dx={10}
        dy={-4}
        style={[{
          fill: '#fff',
          fontSize: 10,
        }]}
        text=""
        x={x}
        y={y}
      />
    </g>
  );
}

/**
 * Componente que define um ponto do `<Grafico>`.
 */
export default Ponto;
