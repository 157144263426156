import React, { useState } from 'react';

import FormularioPessoaPorCidade from './componentes/FormularioPessoaPorCidade';
import FormularioPessoaPorPerfil from './componentes/FormularioPessoaPorPerfil';
import FormularioPessoaPorRegiao from './componentes/FormularioPessoaPorRegiao';
import FormularioPessoaPorSubRegiao from './componentes/FormularioPessoaPorSubRegiao';
import TipoSelecao from './componentes/TipoSelecao';

import { FormularioEstilizado } from './estilos';

function Formulario() {
  const [tipo, defTipo] = useState<string>('');

  return (
    <FormularioEstilizado>
      <TipoSelecao {...{ tipo, defTipo }} />
      {tipo === 'pessoas-por-cidade' && <FormularioPessoaPorCidade />}
      {tipo === 'pessoas-por-perfil' && <FormularioPessoaPorPerfil />}
      {tipo === 'pessoas-por-regiao' && <FormularioPessoaPorRegiao />}
      {tipo === 'pessoas-por-sub-regiao' && <FormularioPessoaPorSubRegiao />}
    </FormularioEstilizado>
  );
}

export default Formulario;