import styled from 'styled-components';

export const RodapeEstilizado = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px;
`;

export const NumeroDeIndices = styled.span`
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  text-align: right;

  @media screen and (max-width: 360px)  {
    width: min-content;
  }
`;

export const FormularioGrupoDeBotao = styled.div`
  && {
    display: grid;
    grid-template-columns: min-content auto;
    align-items: center;
    
    span {
      white-space: nowrap;
      color: #fff;
      font-weight: bold;
      margin-right: 15px;
    }

    button.MuiToggleButtonGroup-grouped {
      color: rgba(255, 255, 255, 0.3);
      padding: 8px;
    }

    button.Mui-selected {
      background-color: #18262f;
      color: #fff;
    }

    @media screen and (max-width: 480px) {
      grid-template-columns: unset;
      grid-template-rows: min-content auto;
      
      span {
        margin-right: 0;
        margin-bottom: 4px;
        font-size: 13px;
      }

      button.MuiToggleButtonGroup-grouped {
        padding: 0 8px;
      }
    }
  }
`;
