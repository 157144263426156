import React, {
  memo,
} from 'react';

import {
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';

import { BarraEstilizada } from './estilos';

export interface PropsBarraDeFerramentas {
  /** Booleano que define renderização do botão de "Colunas" na barra de ferramentas */
  usarColunas?: true;
  /** Booleano que define renderização do botão de "Densidade" na barra de ferramentas */
  usarDensidade?: true;
  /** Booleano que define renderização do botão de "Exportar" na barra de ferramentas */
  usarExportar?: true;
  /** Booleano que define renderização do botão de "Filtros" na barra de ferramentas */
  usarFiltros?: true;
}

function BarraDeFerramentas(props: PropsBarraDeFerramentas) {
  return (
    <BarraEstilizada>
      {props.usarFiltros && <GridToolbarFilterButton />}
      {props.usarColunas &&  <GridToolbarColumnsButton touchRippleRef={null} />}
      {props.usarDensidade &&  <GridToolbarDensitySelector touchRippleRef={null} />}
      {props.usarExportar &&  <GridToolbarExport touchRippleRef={null} />}
    </BarraEstilizada>
  );
}

/**
 * Barra de ferramentas customizavel da `<GradeDeDados>`
 */
export default memo(BarraDeFerramentas);
