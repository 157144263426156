import Obtem from '../Obtem';
import type { Cidade, CidadeDaApi } from './tipos';

/**
 * Classe para requisição GET /cidades da `api-endereços`.
 * Retorna uma lista de objetos com id e nome de uma dada cidade.
 */
export default class ObtemListaDeCidades extends Obtem<Cidade[]> {
  protected readonly URLBase: string = process.env.REACT_APP_API_ENDERECOS ?? ''; // eslint-disable-line
  protected readonly rota: string;

  constructor(token: string, parametros: URLSearchParams) {
    super(token);
    this.rota = this.definirRota(parametros);
  }

  protected definirRota(parametros: URLSearchParams): string {
    const parametrosDeBusca = parametros.toString();
    return this.URLBase + `/cidades?${parametrosDeBusca}&distritos=false`;
  }

  public async resultado(): Promise<Cidade[]> {
    try {
      const { data, status } = await this.conexao.get<CidadeDaApi[]>(this.rota);
      if (status === 200) {
        return data.map(({ idcidade, cidade }) => ({
          idCidade: idcidade,
          nome: cidade,
        }));
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  // Se for necessário manter os ambientes consistentes, adicionar este código
  // const URLBase = this.conexao.defaults.baseURL;
  // if (URLBase?.includes('localhost') || URLBase?.includes('homologacao')) {
  //   return 'https://api-enderecos-develop.rj.r.appspot.com/';
  // }
  // return 'https://api-enderecos-production.rj.r.appspot.com/';
}