import React from 'react';

import Formulario from './componentes/Formulario';
import Logo from './componentes/Logo';

import {
  CabecalhoEstilizado,
  Divisor,
} from './estilos';

function Cabecalho() {
  return (
    <CabecalhoEstilizado>
      <Logo />
      <Divisor
        flexItem
        orientation="vertical"
      />
      <Formulario />
    </CabecalhoEstilizado>
  );
}

export default Cabecalho;
