import { createGlobalStyle } from 'styled-components';

const EstilosGlobais = createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    font-family: "Open Sans", "Roboto Condensed", "Roboto", "Helvetica", "Arial", sans-serif;
    overscroll-behavior: contain;

     /* CSS para Firefox */
    scrollbar-color: rgba(0,0,0,0.3) transparent;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    transition: .5s;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb:focus {
    border-radius: 10px;
    background: rgba(0,0,0,0.6); 
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.3); 
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(0,0,0,0.2);
  }
`;

/*
  Exportação de Temas
  Adicionar exports dos temas do App
 */
export {};

export default EstilosGlobais;