import React, {
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';

import type { Cidade } from '../../../../../../api/obtem/ObtemListaDeCidades/tipos';

import {
  ContextoDeDados,
  ContextoDeVisualizacao,
} from '../../../../../../contextos';

import { Autocompletar } from '../../../../../';

import type { SetState } from '../../../../../../util/tipos';

const TIPO = 'pessoas-por-cidade';

function FormularioPessoaPorCidade() {
  const { obterListaDeCidades } = useContext(ContextoDeDados);
  const { obterIndicesDePessoas } = useContext(ContextoDeVisualizacao);

  /**
   * Estado de controle do valor do autocomplete.
   */
  const [valorSelecionado, defValorSelecionado] = useState<Cidade | null>(null);

  /**
   * Estado que define se o componente está carregando.
   */
  const [carregando, defCarregando] = useState<boolean>(false);

  /**
   * Referencia que monitora o id de timeout da requisição ao digitar.
   */
  const refRequisicao = useRef(-1);

  /**
   * Callback chamado ao digitar no Autocompletar
   */
  const aoDigitar = useCallback((valor: string, defOpcoes: SetState<Cidade[]>) => {
    window.clearTimeout(refRequisicao.current);
    refRequisicao.current = window.setTimeout(() => {
      defCarregando(true);
      obterListaDeCidades({ uf: 'SP', nome: valor })
        .then(resultado => defOpcoes(resultado))
        .catch(erro => console.error(erro))
        .finally(() => {
          defCarregando(false);
        });
    }, 800);
  }, []);

  /**
   * Callback chamado ao selecionar uma opção do Autocompletar.
   */
  const aoSelecionar = useCallback((valor: Cidade | null) => {
    defValorSelecionado(valor);
    if (valor) obterIndicesDePessoas({ tipo: TIPO, cidade: valor.idCidade.toString() });
  }, []);

  /**
   * Callback chamado no inicio da renderização para carregar a lista inicial de cidades.
   */
  const carregarListaDeOpcoes = useCallback((defOpcoes: SetState<Cidade[]>) => {
    defCarregando(true);
    obterListaDeCidades({ uf: 'SP', nome: '' })
      .then(resultado => defOpcoes(resultado))
      .catch(erro => console.error(erro))
      .finally(() => {
        defCarregando(false);
      });
  }, []);

  return (
    <Autocompletar
      {...{
        aoDigitar,
        aoSelecionar,
        carregarListaDeOpcoes,
        carregando,
      }}
      placeholder="Selecione uma cidade"
      semOpcoes="Nenhuma cidade encontrada"
      propRotuloDeOpcao="nome"
      valor={valorSelecionado}
    />
  );
}

/**
 * Componente Autocompletar para seleção de uma cidade.
 */
export default FormularioPessoaPorCidade;