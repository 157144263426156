import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';

interface PropsConteiner {
  tela?: boolean;
  caixa?: boolean;
  // barra?: boolean;
  // input?: boolean;
}

export const Conteiner = styled.div<PropsConteiner>`
  && {
    display: flex;
    justify-content: center;
    padding: ${props => props.tela ? '20vh;' : '5vh;'};
    box-sizing: border-box;
    ${props => !props.caixa && 'max-width: 600px'};
    margin: 0 auto;

  /* CSS de loading na tela inteira */
  ${props => props.tela && `
    width: 100vw;
    height: 100vh;
  `}

  /* CSS de loading em uma caixa inteira */
  ${props => props.caixa && `
    align-items: center;
    width: 100%;
    height: 100%;

  `}
  }

  body {
    overflow-y: hidden;
  }
`;
/*
  CSS de loading em uma barra (lateral/superior)
  Ajustar para o projeto atual caso necessário
*/
/* ${props => props.barra && `
  padding: 0;
  margin-right: var(--espacamento-s);
`} */

/*
  CSS de loading em um input
  Ajustar para o projeto atual caso necessário
*/
/* ${props => props.input && `
  position: absolute;
  right: 6px;
  bottom: 8px;
  padding: 0;
  margin: 0;
`} */

/* .MuiCircularProgress-colorPrimary {
  ${props => props.tela && `
    margin-top: 50px;
  `}
  color: var(--app-primary-color);
} */
interface PropsCarregamento {
  variante: 'tela' | 'caixa';
  tamanho?: number
}

function Carregamento({ variante, tamanho }: PropsCarregamento) {
  return (
    <Conteiner
      tela={variante === 'tela'}
      caixa={variante === 'caixa'}
      // barra={variante === 'barra'}
      // input={variante === 'input'}
    >
      <CircularProgress size={variante === 'tela' ? 40 : tamanho}/>
    </Conteiner>
  );
}

/**
 * Componente genérico de overlay de carregamento.
 * 
 * Possui variantes para tela inteira e "caixas".
 */
export default Carregamento;