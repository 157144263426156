import React, {
  memo,
} from 'react';

import type { DataGridProps } from '@mui/x-data-grid';

import { TEXTO_LOCALIZADO } from './locale';

import BarraDeFerramentas from './componentes/BarraDeFerramentas';

import {
  Grade,
  IconeFiltroAtivo,
  IconeOrdemAscendente,
  IconeOrdemDescendente,
  IconeSemOrdenacao,
} from './estilos';

import type { PropsBarraDeFerramentas } from './componentes/BarraDeFerramentas';
import type { Estilizacao } from '../../util/tipos';

interface PropsGradeDeDados extends DataGridProps, PropsBarraDeFerramentas {
  /** Estilização através de `styled-components` */
  estilos?: Estilizacao;
}

function GradeDeDados({ usarColunas, usarFiltros, usarDensidade, usarExportar, ...props }: PropsGradeDeDados) {
  return (
    <Grade
      {...props}
      localeText={TEXTO_LOCALIZADO}
      components={{
        ColumnFilteredIcon: IconeFiltroAtivo,
        ColumnSortedAscendingIcon: IconeOrdemAscendente,
        ColumnSortedDescendingIcon: IconeOrdemDescendente,
        ColumnUnsortedIcon: IconeSemOrdenacao,
        Toolbar: () => <BarraDeFerramentas { ...{ usarFiltros, usarColunas, usarDensidade, usarExportar }} />,
        ...props.components,
      }}
    />
  );
}

/**
 * Componente genérico para visualização de dados em grade.
 * Extende o componete `<DataGrid>` do MUI.
 */
export default memo(GradeDeDados);
