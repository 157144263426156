import Armazenamento from './Armazenamento';

/**
 * Classe para controle do `sessionStorage`.
 */
export default class ArmazenamentoLocal<T> extends Armazenamento<T> {
  protected item: string;

  constructor(chave: string) {
    super(chave);
    this.item = localStorage.getItem(this.chave) ?? '';
  }

  salvar(valor: T): void {
    localStorage.setItem(this.chave, JSON.stringify(valor));
    this.item = localStorage.getItem(this.chave) ?? '';
  }
}