import styled from 'styled-components';

export const Corpo = styled.div`
  && {
    display: grid;
    grid-template-rows: min-content auto min-content;
    grid-template-columns: 100%;
    height: 100vh;
    background-color: #0D171B;
  }
`;
