import React, {
  memo,
  useContext,
} from 'react';

import {
  getGridStringOperators,
  getGridNumericOperators,
} from '@mui/x-data-grid';

import {
  GridColDef,
  GridColTypeDef,
} from '@mui/x-data-grid';

import type { DadosPessoa } from '../../../../../../../../api/obtem/ObtemIndices/tipos';

import { ContextoDeVisualizacao } from '../../../../../../../../contextos';

import { GradeDeDados } from '../../../../../../../../componentes';

import CorPessoa from './componentes/CorPessoa';

import { Celula } from './estilos';

export interface LinhaPessoa {
  id: number;
  apelido: string;
  cor: DadosPessoa['cor'];
  indice: number;
  nome: string;
}

interface PropsGradeDePessoas {
  pessoas: LinhaPessoa[];
}

function GradeDePessoas(props: PropsGradeDePessoas) {
  const { AbrirCadastroAppVoluntario } = useContext(ContextoDeVisualizacao);

  const customizacoes: Record<keyof LinhaPessoa | 'acoes', GridColTypeDef> = {
    acoes: {
      type: 'actions',
      minWidth: 150,
      flex: 0.3,
      hideable: false,
      renderCell: (params) => <AbrirCadastroAppVoluntario idPessoa={params.row.id} />,
      renderHeader: () => <></>,
    },
    apelido: {
      type: 'string',
      minWidth: 100,
      flex: 0.3,
      filterOperators: getGridStringOperators()
        .filter(op => op.value === 'startsWith'),
    },
    cor: {
      type: 'string',
      maxWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => <CorPessoa idCor={params.row.cor.cor_id} />,
      valueGetter: (params) => params.value.cor_descricao,
      filterOperators: getGridStringOperators()
        .filter(op => op.value === 'startsWith'),
    },
    id: {
      type: 'number',
      maxWidth: 80,
      filterOperators: getGridNumericOperators()
        .filter(op => op.value === 'isAnyOf'),
    },
    indice: {
      type: 'number',
      minWidth: 70,
      flex: 0.15,
      valueFormatter: (params) => `${params.value}%`,
      filterOperators: getGridNumericOperators()
        .filter(op => ['=', '>', '<', '<=', '>='].includes(op.value ?? '')),
    },
    nome: {
      type: 'string',
      minWidth: 240,
      flex: 1,
      filterOperators: getGridStringOperators()
        .filter(op => ['startsWith', 'contains'].includes(op.value ?? ''))
        .sort(op => op.value === 'contains' ? -1 : 1),
    },
  };

  const colunas: GridColDef<LinhaPessoa>[] = [
    { field: 'id', headerName: 'Código', ...customizacoes.id },
    { field: 'indice', headerName: 'Índice', ...customizacoes.indice },
    { field: 'cor', headerName: 'Cor', ...customizacoes.cor },
    { field: 'nome', headerName: 'Nome', ...customizacoes.nome },
    { field: 'apelido', headerName: 'Apelido', ...customizacoes.apelido },
    { field: 'acoes', headerName: 'Ações', ...customizacoes.acoes },
  ];

  return (
    <GradeDeDados
      usarFiltros
      usarColunas
      components={{ Cell: Celula }}
      columns={colunas}
      rows={props.pessoas}
      disableSelectionOnClick
      disableColumnMenu
    />
  );
}

/**
 * Componente de visualização de dados de pessoas em grade.
 */
export default memo(GradeDePessoas);
