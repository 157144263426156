import styled from 'styled-components';

export const Conteudo = styled.div`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #fff;

    text-align: center;
    font-size: 1.5rem;
  }
`;
