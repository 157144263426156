import React, {
  memo,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';

import { ContextoDeGrafico } from '../../../../../../../../contextos';

import Carregamento from '../../../../../../../../componentes/Carregamento';

import DetalhesPessoas from './componentes/DetalhesPessoas';

import {
  BarraSuperior,
  CaixaFlutuante,
  Conteudo,
} from './estilos';

function DialogoDeDetalhes() {
  const {
    dadosDetalhes,
    defDetalhesVisivel,
    defPontoSelecionado,
    detalhesVisivel,
    pontoSelecionado,
  } = useContext(ContextoDeGrafico);

  /**
   * Memo que monitora se os dados do dialogo estão carregando.
   */
  const carregando = useMemo(() => dadosDetalhes === undefined, [dadosDetalhes]);

  /**
   * Callback chamado ao fechar `<DialogoDeDetalhes>`
   */
  const aoFechar = useCallback(() => {
    const marcador = document.querySelector<SVGSVGElement>(`#pontos-dados-${pontoSelecionado} svg`);
    if (marcador) {
      marcador.style.display = 'none';
      marcador.style.animationPlayState = 'paused';
    }
    defPontoSelecionado(-1);
    defDetalhesVisivel(false);
  }, [pontoSelecionado, defDetalhesVisivel, defPontoSelecionado]);

  return (
    <CaixaFlutuante
      visivel={detalhesVisivel}
      carregandoDados={carregando}
    >
      {
        carregando
          ? <Carregamento variante="caixa" tamanho={40} /> /* eslint-disable-line */
          : (
            <>
              <BarraSuperior>
                <IconButton onClick={aoFechar}>
                  <CloseIcon style={{ fontSize: 20, color: '#888' }} />
                </IconButton>
              </BarraSuperior>
              <Conteudo className="DialogoDeDetalhes-corpo">
                {dadosDetalhes && <DetalhesPessoas dados={dadosDetalhes} />}
              </Conteudo>
            </>
          )
      }
    </CaixaFlutuante>
  );
}
/**
 * Dialogo de detalhes para visualizaçao de dados no grafico.
 */
export default memo(DialogoDeDetalhes);
