import React, {
  memo,
} from 'react';

import { ProvedorDeVisualizacao } from '../../../../contextos/ContextoDeVisualizacao';

import Tela from './componentes/Tela';

import { Corpo } from './estilos';

function Basometro() {
  return (
    <Corpo>
      <ProvedorDeVisualizacao>
        <Tela />
      </ProvedorDeVisualizacao>
    </Corpo>
  );
}

/**
 * Componente da aplicação.
 * Define a estrutura do app.
 */
export default memo(Basometro);
