import React, {
  memo,
} from 'react';

import Avatar from '@mui/material/Avatar';

import { Emblema } from './estilos';

interface PropsIcone {
  cor: string;
  foto: string;
}

function Icone(props: PropsIcone) {
  return (
    <Emblema
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      cor={props.cor}
      overlap="circular"
      variant="dot"
    >
      <Avatar src={`data:image/png;base64,${props.foto}`} />
    </Emblema>
  );
}

/**
 * Icone de foto da pessoa.
 * 
 * Usa a base64 retornada pela api.
 */
export default memo(Icone);
