import styled from 'styled-components';

export const FormularioEstilizado = styled.div`
  && {
    color: #fff;
    display: flex;
    
    & > * {
      max-width: 400px;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
    
    @media screen and (max-width: 360px) {
      display: block;

      & > * {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;