import styled from 'styled-components';

import {
  DataGrid,
  GridFilterAltIcon,
  GridArrowUpwardIcon,
  GridArrowDownwardIcon,
} from '@mui/x-data-grid';

import { Estilizacao } from '../../util/tipos';

interface PropsGrade {
  estilos?: Estilizacao;
}

export const Grade = styled(DataGrid)<PropsGrade>`
  && {
    background-color: rgb(24, 38, 47);
    color: #fff;
    border: 0;

    .MuiDataGrid-virtualScroller {
      scrollbar-color: rgb(114, 120, 124, 0.4) rgb(24, 38, 47);

      ::-webkit-scrollbar-thumb {
        background: rgb(114, 120, 124, 0.4);
      }
    }
    
    .MuiDataGrid-columnHeaders {
      border: inherit;
      
      .MuiDataGrid-columnHeader {
        .MuiDataGrid-iconButtonContainer svg {
          font-size: 18px;
        }
      }

      .MuiDataGrid-menuIcon svg {
        font-size: 18px;
        color: #fff;
      }
    }
    
    .MuiDataGrid-row {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      
      &:hover, &.Mui-selected:hover {
        background-color: rgb(13, 23, 27, 0.5);
      }

      &.Mui-selected {
        background-color: rgb(13, 23, 27);
      }

      .MuiDataGrid-cell {
        border: 0;
        
        .botao-cadastro-voluntario {
          white-space: normal;
          line-height: 1.25em;
        }
      }
    }
    
    .MuiDataGrid-footerContainer {
      border: inherit;

      .MuiTablePagination-root {
        color: inherit;
      }
    }

    ${({ estilos }) => estilos ? (
    Object.entries(estilos).reduce((total, [regra, propriedades]) => (
      total
        + regra
        + JSON.stringify(propriedades)
          .replace(/,/g, ';')
          .replace(/"/g, '')
        + '\n'
    ), '')
  ) : ''}
  }
`;

export const IconeFiltroAtivo = styled(GridFilterAltIcon)`
  && { color: #fff; }
`;

export const IconeOrdemDescendente = styled(GridArrowDownwardIcon)`
  && { color: #fff; }
`;

export const IconeOrdemAscendente = styled(GridArrowUpwardIcon)`
  && { color: #fff; }
`;

export const IconeSemOrdenacao = styled(GridArrowDownwardIcon)`
  && { color: #777; }
`;