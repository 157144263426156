import styled from 'styled-components';

import Divider from '@mui/material/Divider';

export const CabecalhoEstilizado = styled.header`
  && {
    display: grid;
    grid-template-columns: min-content min-content auto;
    align-items: center;
    margin: 15px;

    @media screen and (max-width: 480px) {
      display: block;
    }
  }
`;

export const Divisor = styled(Divider)`
  && {
    margin: 0 25px;
    border-color: rgba(255, 255, 255, 0.2);

    @media screen and (max-width: 480px) {
      display: none;
    }
  }
`;
