import React from 'react';

import {
  LineSegment,
  LineSegmentProps,
} from 'victory';

function PassoDoEixo(props: LineSegmentProps) {
  const semLabel = props.datum % 10 !== 0;

  return (
    <>
      <LineSegment
        {...props}
        x1={0}
        // @ts-expect-error Tipo de 'x2' não considera 'string' mas svg nativo considera
        x2={semLabel ? '100%' : 4}
      />
      <LineSegment
        {...props}
        x1={props.datum === 100 ? 34 : props.datum < 10 ? 22 : 28}
        // @ts-expect-error Tipo de 'x2' não considera 'string' mas svg nativo considera
        x2="100%"
        style={{ ...props.style, display: semLabel ? 'none' : '' }}
      />
    </>
  );
}

/**
 * Componente que define cada "pulo" do grafico.
 */
export default PassoDoEixo;
