import React, {
  memo,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { ContextoDeVisualizacao } from '../../../../../../contextos';

import {
  Carregamento,
  Grafico,
} from '../../../../../../componentes';

import GradeDePessoas from './componentes/GradeDePessoas';
import DialogoDeDetalhes from './componentes/DialogoDeDetalhes';
import TelaVazia from './componentes/TelaVazia';

import { AreaFlutuante } from './estilos';

import type { LinhaPessoa } from './componentes/GradeDePessoas';

function Tela() {
  const {
    indices,
    modo,
    status,
  } = useContext(ContextoDeVisualizacao);

  /** Memo que controla o atual conteudo da `<Tela>` */
  const conteudo = useMemo(() => {
    if (status === 'sem-dados')
      return <TelaVazia />;
    else if (status === 'carregando')
      return <Carregamento variante="caixa" tamanho={50} />;

    // caso existam dados, verificar o modo de visualização
    else if (modo === 'lista') {
      const dados: LinhaPessoa[] = indices.map(item => ({
        id: item.dados.id_pessoa,
        nome: item.dados.nome,
        apelido: item.dados.apelido,
        indice: item.indice,
        cor: item.dados.cor,
      }));

      return <GradeDePessoas pessoas={dados} />;
    } else {
      // por padrão, visualização em `<Grafico>`
      return (
        <>
          <Grafico />
          <DialogoDeDetalhes />
        </>
      );
    }
  }, [modo, status]);

  const aoClicar = useCallback((e: React.SyntheticEvent<HTMLDivElement>) => {
    e.currentTarget.focus();
  }, []);

  return (
    <AreaFlutuante
      id="tela"
      tabIndex={-1} // "tabindex" para componente ser "focável"
      onClick={aoClicar}
    >
      {conteudo}
    </AreaFlutuante>
  );
}

/**
 * Tela usada para mostrar informações ao usuário.
 *
 * Possui um estado inicial sem dados e ao buscar dados,
 * define um grafico ou uma lista, dependendo do modo de visualização,
 * para visualização dos dados retornados.
 */
export default memo(Tela);
