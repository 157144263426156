import type { FunctionComponent } from 'react';

import { InputLabel, Select } from '@mui/material';
import type { SelectProps } from '@mui/material';

import styled from 'styled-components';

type ComponenteSelecao = FunctionComponent<SelectProps<string>>;

export const SelecaoEstilizada = styled<ComponenteSelecao>(Select)`
  && {
    background-color: #fff;
  }
  .MuiSelect-select {
    padding: 7px 10px;
  }
`;

export const RotuloEstilizado = styled(InputLabel)`
  && {
    top: -9px;
  }
  &&.MuiInputLabel-shrink {
    top: 2px;
  }
  &&.MuiInputLabel-shrink.MuiInputLabel-filled {
    display: none;
  }
`;