import React from 'react';

import {
  FormControl,
  MenuItem,
} from '@mui/material';

import {
  RotuloEstilizado,
  SelecaoEstilizada,
} from './estilos';

import type { SetState } from '../../../../../../util/tipos';

interface PropsTipoSelecao {
  defTipo: SetState<string>;
  tipo: string;
}

function TipoSelecao(props: PropsTipoSelecao) {
  return (
    <FormControl fullWidth>
      <RotuloEstilizado
        id="rotulo"
        variant="filled"
        margin="dense"
      >
        Selecione o filtro
      </RotuloEstilizado>
      <SelecaoEstilizada
        labelId="rotulo"
        value={props.tipo}
        onChange={(event) => props.defTipo(event.target.value || '')}
      >
        <MenuItem value="pessoas-por-cidade">Pessoas por cidade</MenuItem>
        <MenuItem value="pessoas-por-perfil">Pessoas por perfil</MenuItem>
        <MenuItem disabled value="pessoas-por-regiao">Pessoas por região</MenuItem>
        <MenuItem disabled value="pessoas-por-sub-regiao">Pessoas por sub-região</MenuItem>
      </SelecaoEstilizada>
    </FormControl>
  );
}

/**
 * Componente de seleção do tipo de filtro de dados.
 */
export default TipoSelecao;