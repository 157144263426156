import styled from 'styled-components';

import { GridCell } from '@mui/x-data-grid';

export const Celula = styled(GridCell)`
  && {
    &[data-field="acoes"] {
      padding: 0;
    }
  }
`;
