/**
 * Retorna um valor que representa o quão escuro ou claro é uma cor.
 * O resultado estará no intervalo de 0 a 255,
 * onde 0 é mais escuro e 255 mais claro. Valor médio é 128
 */
export function obterLuminosidade(color: string) {
  if (color[0] === '#') {
    const hexValue = color.substring(1); // corta o #
    const rgb = parseInt(hexValue, 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;
    return 0.2126 * r + 0.7152 * g + 0.0722 * b; // calculo de claridade
  }
  const valuesInStr = color.match(/(?<=\()[^)]*(?=\))/g);
  const [r, g, b] = valuesInStr?.pop()?.split(',') ?? [0, 0, 0];
  return 0.2126 * Number(r) + 0.7152 * Number(g) + 0.0722 * Number(b); // calculo de claridade
}
