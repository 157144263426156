import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import type { SyntheticEvent } from 'react';

import {
  VictoryAxis,
  VictoryClipContainer,
  VictoryChart,
  VictoryLabel,
  VictoryScatter,
  VictoryZoomContainer,
} from 'victory';

import {
  ContextoDeGrafico,
  ContextoDeVisualizacao,
} from '../../contextos';

import PassoDoEixo from './componentes/PassoDoEixo';
import Ponto, { PropsPonto } from './componentes/Ponto';

import { ArmazenamentoLocal } from '../../util';
import { DadosCor } from '../../util/tipos';
import { Indices } from '../../api/obtem/ObtemIndices/tipos';

/*
  TODO: Possivelmente, pode ser um grafico que pode variar de forma
  Ver como implementar caso exista mais de um tipo de gráfico a usar
  Ex: prop `variante` que altera qual componente Victory renderiza os dados
      Nesse caso é `<VictoryScatter />`
*/

interface Proporcao {
  altura?: number;
  largura?: number;
}

function Grafico() {
  const { indices } = useContext(ContextoDeVisualizacao);
  const { marcarPonto } = useContext(ContextoDeGrafico);

  const refRedimensiona = useRef<number>(-1);

  const [proporcaoGrafico, defProporcaoGrafico] = useState<Proporcao>({ altura: 0, largura: 0 });

  const aoClicarEmPonto = useCallback((e: SyntheticEvent<SVGGElement, Event>, propsPonto: PropsPonto) => {
    e.preventDefault();
    marcarPonto(propsPonto);
  }, []);

  const aoPassarPorPonto = useCallback((e: SyntheticEvent<SVGGElement, Event>, propsPonto: PropsPonto) => {
    const rotulo = e.currentTarget.querySelector('tspan');
    if (rotulo) rotulo.textContent = e.type === 'mouseenter' ? (propsPonto.datum?.rotulo ?? '') : '';
  }, []);

  const colorirPonto = useCallback((datum: Indices & { _y: number; _x: number; }) => {
    const armazenamento = new ArmazenamentoLocal<DadosCor[]>('cores');
    const cores = armazenamento.existe() ? armazenamento.obter() : [];
    const cor = cores.find(item => (item.idCor === datum?.dados.cor['cor_id']));
    if (cor) return cor.hexa;
    return 'white';
  }, []);

  /**
   * useEffect que inicializa a renderização correta do SVG
   * e atribui evento de `resize` à janela para permitir responsividade.
   */
  useEffect(() => {
    const area = document.querySelector<HTMLDivElement>('#tela');
    if (area) {
      const proporcao = () => ({
        altura: area.clientHeight / 1.5,
        largura: area.clientWidth / 1.5,
      });
      defProporcaoGrafico(proporcao);
      window.onresize = () => {
        window.clearTimeout(refRedimensiona.current);
        refRedimensiona.current = window.setTimeout(() => defProporcaoGrafico(proporcao), 100);
      };
    }
  }, []);

  return (
    <VictoryChart
      containerComponent={
        <VictoryZoomContainer
          style={{ position: 'absolute', inset: 0 }}
          minimumZoom={{ y: 20 }}
          clipContainerComponent={
            <VictoryClipContainer
              clipPadding={{
                top: 10,
                bottom: 10,
                left: 10,
                right: 10,
              }}
            />
          }
        />
      }
      domain={{ x: [0, indices.length], y: [0, 100] }}
      height={proporcaoGrafico.altura}
      width={proporcaoGrafico.largura}
      padding={{
        top: 20,
        bottom: 20,
        left: 40,
        right: 0,
      }}
      events={[
        {
          childName: 'pontos',
          target: 'data',
          eventHandlers: {
            onClick: aoClicarEmPonto,
            onMouseEnter: aoPassarPorPonto,
            onMouseLeave: aoPassarPorPonto,
          },
        },
      ]}
    >
      <VictoryAxis
        dependentAxis
        crossAxis={false}
        tickCount={3}
        tickFormat={tick => tick % 10 === 0 ? `${tick}%` : ''}
        tickComponent={<PassoDoEixo />}
        tickLabelComponent={
          <VictoryLabel
            textAnchor="start"
            dx={-24}
          />
        }
        style={{
          axis: { stroke: 'transparent' },
          ticks: { stroke: 'gray' },
          tickLabels: { fontSize: 14, fill: '#ccc' },
        }}
      />
      <VictoryScatter name="pontos"
        style={{
          data: { fill: ({ datum }) => colorirPonto(datum) },
        }}
        data={indices}
        dataComponent={<Ponto />}
        y="indice"
      />
    </VictoryChart>
  );
}

/**
 * Componente grafico "Scatter" do Victory.
 * 
 * Recebe altura e largura para definir resolução do svg.
 */
export default memo(Grafico);
