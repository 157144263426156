import React, {
  createContext,
  useCallback,
  useState,
  useEffect,
  // useMemo,
  // useState,
} from 'react';

import {
  ObtemCores,
  ValidaTokenDeSessao,
} from '../../api';

import { ArmazenamentoLocal } from '../../util';
import { DadosCor } from '../../util/tipos';

interface RecursosDoApp {
  token: string;
  validarTokenDeSessao: () => Promise<boolean>;
}

const ContextoDoApp = createContext({} as RecursosDoApp);

interface PropsProvedorDoApp {
  children: React.ReactNode;
}

export function ProvedorDoApp(props: PropsProvedorDoApp) {
  /**
   * Estado que armazena o token de sessao validado pelo app.
   */
  const [token, defToken] = useState<string>('');

  const validarTokenDeSessao = useCallback(async () => {
    const tokenDeSessao = new URLSearchParams(location.search).get('session_token') || '';
    const validacao = new ValidaTokenDeSessao(tokenDeSessao);
    const validado = await validacao.executar();
    if (validado) defToken(tokenDeSessao);
    return validado;
  }, []);

  /**
   * Esse callback chama o endpoint GET /cores da api-pessoas.
   * As cores retornadas são armazenadas em localStorage para uso posterior.
   */
  const carregarCores = useCallback(async () => {
    const obtem = new ObtemCores(token);
    const resultado = await obtem.resultado();
    return resultado;
  }, [token]);

  const salvarCores = useCallback((dados: DadosCor[]) => {
    const armazenamento = new ArmazenamentoLocal<DadosCor[]>('cores');
    armazenamento.salvar(dados);
  }, []);

  /**
   * Esse efeito carrega informações, pelas apis, que serão usadas através do app.
   */
  useEffect(() => {
    // adicionar outras requisições necessárias posteriormente
    // e implementação do `then` caso necessário.
    if (token) {
      Promise.all([carregarCores()])
        .then((resultados) => {
          const [cores] = resultados;
          if (cores) salvarCores(cores);
        })
        .catch(erro => console.error(erro));
    }
  }, [
    carregarCores,
    token,
  ]);

  return (
    <ContextoDoApp.Provider
      value={{
        token,
        validarTokenDeSessao,
      }}
    >
      {props.children}
    </ContextoDoApp.Provider>
  );
}

export default ContextoDoApp;
