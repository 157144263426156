import React, {
  createContext,
  useCallback,
  useContext,
  // useEffect,
  // useMemo,
  // useState,
} from 'react';

import {
  ObtemListaDeCidades,
  ObtemListaDePerfis,
  ObtemPessoa,
} from '../../api';

import type { Cidade } from '../../api/obtem/ObtemListaDeCidades/tipos';
import type { Perfil } from '../../api/obtem/ObtemListaDePerfis/tipos';
import { Pessoa } from '../../api/obtem/ObtemPessoa/tipos';

import { ContextoDoApp } from '../';

import { Requisicao } from '../../util/tipos';

interface RecursosDeDados {
  obterInfoPessoa: (idPessoa: number) => Promise<Pessoa | undefined>;
  obterListaDeCidades: Requisicao<Cidade[]>;
  obterListaDePerfis: Requisicao<Perfil[]>;
}

const ContextoDeDados = createContext({} as RecursosDeDados);

interface PropsProvedorDeDados {
  children: React.ReactNode;
}

export function ProvedorDeDados(props: PropsProvedorDeDados) {
  const { token } = useContext(ContextoDoApp);

  const obterListaDeCidades = useCallback<Requisicao<Cidade[]>>(async (parametros) => {
    const obtem = new ObtemListaDeCidades(token, new URLSearchParams(parametros));
    const resultado = await obtem.resultado();
    return resultado;
  }, [token]);

  const obterListaDePerfis = useCallback<Requisicao<Perfil[]>>(async (parametros) => {
    const obtem = new ObtemListaDePerfis(token, new URLSearchParams(parametros));
    const resultado = await obtem.resultado();
    return resultado;
  }, [token]);

  /**
   * Função que realiza a busca de uma pessoa.
   */
  const obterInfoPessoa = useCallback(async (idPessoa: number) => {
    const obtem = new ObtemPessoa(token, idPessoa);
    const resultado = await obtem.resultado();
    return resultado;
  }, [token]);

  return (
    <ContextoDeDados.Provider
      value={{
        obterInfoPessoa,
        obterListaDeCidades,
        obterListaDePerfis,
      }}
    >
      {props.children}
    </ContextoDeDados.Provider>
  );
}

export default ContextoDeDados;
