import React, { memo } from 'react';
import styled from 'styled-components';

import Button, { ButtonProps } from '@mui/material/Button';

interface PropsBotao {
  /**
   * Define a cor do texto do botão
   * @default '#fff'
   */
  cor?: React.CSSProperties['color'];
  /**
   * Define a cor do botão
   * @default 'rgb(0, 185, 250)'
   */
  corDeFundo?: React.CSSProperties['backgroundColor'];
}

const Botao = styled(Button)<PropsBotao>`
  && {
    background-color: ${({ corDeFundo }) => (corDeFundo || 'rgb(0, 185, 250)')};
    color: ${({ cor }) => (cor || '#fff')};
    font-size: 14px;
    font-weight: bold;

    &:hover {
      background-color: ${({ corDeFundo }) => (corDeFundo || 'rgba(0, 185, 250, 0.8)')};
    }

    &.Mui-disabled {
      background-color: #888;
      color: #cecece;
      
      pointer-events: auto;
      cursor: not-allowed;
    }
  }
`;

/**
 * Botão estilizado padrão do app.
 * Extende o componente `<Button>` do MUI.
 * 
 * Recebe as props `cor`(para o texto) e `corDeFundo` (para o botão) para estilização.
 */
export default memo<PropsBotao & ButtonProps>(Botao);
