import React, {
  memo,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

import type {
  Requisicao,
  SetState,
} from '../../util/tipos';

import { ObtemIndicesPessoas } from '../../api';

import type { Indices } from '../../api/obtem/ObtemIndices/tipos';

import { ContextoDoApp } from '../../contextos';

import { ProvedorDeGrafico } from './ContextoDeGrafico';

import {
  Botao,
  Cabecalho,
  Rodape,
} from '../../componentes';

const appVoluntario = process.env.REACT_APP_APP_VOLUNTARIO; // eslint-disable-line

type Status = 'sem-dados' | 'carregando' | 'pessoas';

interface PropsAbrirCadastroAppVoluntario {
  idPessoa: number;
}
type ComponenteAbrirCadastro = React.FunctionComponent<PropsAbrirCadastroAppVoluntario>;

interface RecursosDeVisualizacao {
  defModo: SetState<'grafico' | 'lista'>;
  modo: 'grafico' | 'lista';

  AbrirCadastroAppVoluntario: ComponenteAbrirCadastro // eslint-disable-line
  obterIndicesDePessoas: Requisicao<Indices[], false>;

  indices: Indices[];
  status: Status;
}

const ContextoDeVisualizacao = createContext({} as RecursosDeVisualizacao);

interface PropsProvedorDeVisualizacao {
  children: React.ReactNode;
}

function ComponenteProvedorDeVisualizacao(props: PropsProvedorDeVisualizacao) {
  const { token } = useContext(ContextoDoApp);

  /**
   * Estado que controla o método de visualizacao de dados do basometro.
   */
  const [modo, defModo] = useState<'grafico' | 'lista'>('grafico');

  /**
   * Estado que controla os dados de indices da visualizacao.
   */
  const [indices, defIndices] = useState<Indices[]>([]);

  /**
   * Estado que controla o status da tela.
   * Podendo estar carregando, com dados ou sem dados.
   */
  const [status, defStatus] = useState<Status>('sem-dados');

  /**
   * Função que realiza a busca por indices.
   */
  const obterIndicesDePessoas = useCallback<Requisicao<Indices[], false>>(async (parametros) => {
    defStatus('carregando');
    const obtem = new ObtemIndicesPessoas(token, new URLSearchParams(parametros));
    const resultado = await obtem.resultado();
    defIndices(resultado);
    defStatus(resultado.length > 0 ? 'pessoas' : 'sem-dados');
  }, [token]);

  /**
   * Componente Callback que chama o componente `<Botao>` "Abrir Cadastro"
   * Abre nova aba que redireciona para o cadsatro da pessoa no app-voluntario
   */
  const AbrirCadastroAppVoluntario = useCallback<ComponenteAbrirCadastro>(({ idPessoa }) => {
    const abrirCadastro = () => window.open(appVoluntario + `/pessoa-detalhes/${idPessoa}`, '_blank');
    return (
      <Botao
        className="botao-cadastro-voluntario"
        onClick={abrirCadastro}
      >
        Abrir Cadastro
      </Botao>
    );
  }, []);

  return (
    <ContextoDeVisualizacao.Provider
      value={{
        AbrirCadastroAppVoluntario,
        defModo,
        modo,
        indices,
        obterIndicesDePessoas,
        status,
      }}
    >
      <Cabecalho />
      {
        modo === 'grafico'
          ? <ProvedorDeGrafico>{props.children}</ProvedorDeGrafico>
          : <>{props.children}</>
      }
      <Rodape />
    </ContextoDeVisualizacao.Provider>
  );
}

export const ProvedorDeVisualizacao = memo(ComponenteProvedorDeVisualizacao);

export default ContextoDeVisualizacao;