import React, { useContext } from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { ContextoDeVisualizacao } from '../../../../contextos';

import { FormularioGrupoDeBotao } from '../../estilos';

function ModoDeVisualizacao() {
  const { modo, defModo } = useContext(ContextoDeVisualizacao);

  return (
    <FormularioGrupoDeBotao>
      <span>Visualização:</span>
      <ToggleButtonGroup
        exclusive
        value={modo}
        onChange={(_, value) => defModo(value)}
      >
        <ToggleButton value="grafico">Gráfico</ToggleButton>
        <ToggleButton value="lista">Lista</ToggleButton>
      </ToggleButtonGroup>
    </FormularioGrupoDeBotao>
  );
}

/**
 * Componente de formulario para definir o modo de visualização dos dados.
 */
export default ModoDeVisualizacao;