import styled from 'styled-components';

import { obterLuminosidade } from '../../../../../../../../../../util';

interface PropsMarcaDeCor {
  hexa: string;
}

export const MarcaDeCor = styled.div<PropsMarcaDeCor>`
  && {
    padding: 2px 4px;
    border-radius: 10px;
    width: 100%;
    text-align: center;
    
    color: ${props => obterLuminosidade(props.hexa) <= 80 ? 'white' : 'black'};
    background-color: ${props => props.hexa};
  }
`;