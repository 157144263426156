import React, {
  // useCallback,
  useContext,
  // useRef,
  // useState,
} from 'react';

import {
  ContextoDeDados,
  ContextoDeVisualizacao,
} from '../../../../../../contextos';

import { Autocompletar } from '../../../../../';

const TIPO = 'pessoas-por-regiao';

function FormularioPessoaPorRegiao() {
  const { obterListaDePerfis } = useContext(ContextoDeDados);
  const { obterIndicesDePessoas } = useContext(ContextoDeVisualizacao);

  /**
   * Estado de controle do valor do autocomplete.
   */
  // const [valorSelecionado, defValorSelecionado] = useState<Perfil | null>(null);

  /**
   * Referencia que monitora o id de timeout da requisição ao digitar.
   */
  // const refRequisicao = useRef(-1);

  /**
   * Callback chamado ao digitar no Autocompletar
   */
  // const aoDigitar = useCallback((valor: string, defOpcoes: SetState<Perfil[]>) => {
  //   window.clearTimeout(refRequisicao.current);
  //   refRequisicao.current = window.setTimeout(() => {
  //     obterListaDePerfis({ nome: valor })
  //       .then(resultado => defOpcoes(resultado))
  //       .catch(erro => console.error(erro));
  //   }, 800);
  // }, []);

  /**
   * Callback chamado ao selecionar uma opção do Autocompletar.
   */
  // const aoSelecionar = useCallback((valor: Perfil | null) => {
  //   defValorSelecionado(valor);
  //   if (valor) obterIndicesDePessoas({ tipo: TIPO, perfil: valor.idPerfil.toString() });
  // }, []);

  /**
   * Callback no inciocio da renderização para carregar a lista inicial de perfis.
   */
  // const carregarListaDeOpcoes = useCallback((defOpcoes: SetState<Perfil[]>) => {
  //   obterListaDePerfis({ nome: '' })
  //     .then(resultado => defOpcoes(resultado))
  //     .catch(erro => console.error(erro));
  // }, []);

  return (
    <></>
    // <Autocompletar
    //   {...{
    //     aoDigitar,
    //     aoSelecionar,
    //     carregarListaDeOpcoes,
    //   }}
    //   placeholder="Selecione um perfil"
    //   propRotuloDeOpcao="apelido"
    //   valor={valorSelecionado}
    // />
  );
}

/**
 * Componente Autocompletar para seleção de uma região.
 */
export default FormularioPessoaPorRegiao;