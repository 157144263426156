import React from 'react';

import { LogoEstilizado } from './estilos';

function Logo() {
  return (
    <LogoEstilizado>
      Basômetro
    </LogoEstilizado>
  );
}

/**
 * Logo estilizado do app.
 */
export default Logo;