import React, {
  createContext,
  useCallback,
  useContext,
  // useMemo,
  useState,
} from 'react';

import type { Pessoa } from '../../../api/obtem/ObtemPessoa/tipos';

import { ContextoDeDados } from '../..';

import type { SetState } from '../../../util/tipos';

import type { PropsPonto } from '../../../componentes/Grafico/componentes/Ponto';

interface RecursosDeGrafico {
  dadosDetalhes: Pessoa | undefined;
  detalhesVisivel: boolean;
  pontoSelecionado: number;

  defDetalhesVisivel: SetState<boolean>;
  defDadosDetalhes: SetState<Pessoa | undefined>;
  defPontoSelecionado: SetState<number>;

  marcarPonto: (ponto: PropsPonto) => void;
}

const ContextoDeGrafico = createContext({} as RecursosDeGrafico);

interface PropsProvedorDeGrafico {
  children: React.ReactNode;
}

export function ProvedorDeGrafico(props: PropsProvedorDeGrafico) {
  const { obterInfoPessoa } = useContext(ContextoDeDados);

  /**
   * Estado que monitora o ponto selecionado do `<Grafico>`.
   */
  const [pontoSelecionado, defPontoSelecionado] = useState<number>(-1);

  /**
   * Estado que controla visibilidade do componente `<DialogoDeDetalhes>`
   */
  const [detalhesVisivel, defDetalhesVisivel] = useState<boolean>(false);

  /**
  * Estado que monitora os dados buscados ao clicar em um ponto.
  */
  const [dadosDetalhes, defDadosDetalhes] = useState<Pessoa | undefined>(undefined);

  /**
   * Função que realiza a busca de uma pessoa
   */
  const buscarPessoa = useCallback(async (idPessoa: number) => {
    const resultado = await obterInfoPessoa(idPessoa);
    defDadosDetalhes(resultado);
    defDetalhesVisivel((antigo) => resultado ? true : antigo);
  }, [obterInfoPessoa]);

  /**
   * Função que marca um ponto selecionado.
   */
  const marcarPonto = useCallback((propsPonto: PropsPonto) => {
    defDadosDetalhes(undefined);
    defPontoSelecionado((antigo) => {
      const idponto = propsPonto.index;
      const marcadorAntigo = document.querySelector<SVGSVGElement>(`#pontos-dados-${antigo} svg`);
      const marcadorNovo = document.querySelector<SVGSVGElement>(`#pontos-dados-${idponto} svg`);
      if (!marcadorNovo) return antigo;
      if (marcadorAntigo) {
        marcadorAntigo.style.display = 'none';
        marcadorAntigo.style.animationPlayState = 'paused';
      }
      marcadorNovo.style.display = 'block';
      marcadorNovo.style.animationPlayState = 'running';
      return Number(idponto);
    });
    buscarPessoa(propsPonto.datum?.dados.id_pessoa ?? -1);
  }, [buscarPessoa]);

  return (
    <ContextoDeGrafico.Provider
      value={{
        dadosDetalhes,
        defDadosDetalhes,
        defDetalhesVisivel,
        detalhesVisivel,
        defPontoSelecionado,
        marcarPonto,
        pontoSelecionado,
      }}
    >
      {props.children}
    </ContextoDeGrafico.Provider>
  );
}

export default ContextoDeGrafico;