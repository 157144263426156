import type { GridLocaleText } from '@mui/x-data-grid';

export const TEXTO_LOCALIZADO: GridLocaleText = {
  // Raiz
  noRowsLabel: 'Nenhum resultado.',
  noResultsOverlayLabel: 'Nenhum resultado encontrado.',
  errorOverlayDefaultLabel: 'Ocorreu um erro.',

  // Seletores de densidade da toolbar
  toolbarDensity: 'Densidade',
  toolbarDensityLabel: 'Densidade',
  toolbarDensityCompact: 'Compacto',
  toolbarDensityStandard: 'Padrão',
  toolbarDensityComfortable: 'Comfortável',

  // Seletor de colunas da toolbar
  toolbarColumns: 'Colunas',
  toolbarColumnsLabel: 'Selecionar colunas',

  // Filtro da toolbar
  toolbarFilters: 'Filtros',
  toolbarFiltersLabel: 'Mostrar filtros',
  toolbarFiltersTooltipHide: 'Esconder filtros',
  toolbarFiltersTooltipShow: 'Mostrar filtros',
  toolbarFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,

  // Seletor de exportação da toolbar
  toolbarExport: 'Exportar',
  toolbarExportLabel: 'Exportar',
  toolbarExportCSV: 'Download em CSV',
  toolbarExportPrint: 'Print',
  // toolbarExportExcel: 'Download em Excel',

  // Painel de "Colunas"
  columnsPanelTextFieldLabel: 'Buscar coluna',
  columnsPanelTextFieldPlaceholder: 'Nome da coluna',
  columnsPanelDragIconLabel: 'Reordenar coluna',
  columnsPanelShowAllButton: 'Mostrar todas',
  columnsPanelHideAllButton: 'Esconder todas',

  // Painel de "Filtros"
  filterPanelAddFilter: 'Adicionar filtro',
  filterPanelDeleteIconLabel: 'Deletar',
  filterPanelLinkOperator: 'Operador lógico',
  filterPanelOperators: 'Operador', // TODO v6: rename to filterPanelOperator
  filterPanelOperatorAnd: 'E',
  filterPanelOperatorOr: 'Ou',
  filterPanelColumns: 'Colunas',
  filterPanelInputLabel: 'Valor',
  filterPanelInputPlaceholder: 'Valor do filtro',

  // Operadores do filtro
  filterOperatorContains: 'contém',
  filterOperatorEquals: 'igual a',
  filterOperatorStartsWith: 'começa com',
  filterOperatorEndsWith: 'termina com',
  filterOperatorIs: 'é',
  filterOperatorNot: 'não é',
  filterOperatorAfter: 'está depois',
  filterOperatorOnOrAfter: 'está em ou depois',
  filterOperatorBefore: 'está antes',
  filterOperatorOnOrBefore: 'está em ou antes',
  filterOperatorIsEmpty: 'está vazio',
  filterOperatorIsNotEmpty: 'não está vazio',
  filterOperatorIsAnyOf: 'está em',

  // Valores de filtro
  filterValueAny: 'qualquer',
  filterValueTrue: 'verdadeiro',
  filterValueFalse: 'falso',

  // Menu de coluna
  columnMenuLabel: 'Menu',
  columnMenuShowColumns: 'Mostrar colunas',
  columnMenuFilter: 'Filtro',
  columnMenuHideColumn: 'Esconder coluna',
  columnMenuUnsort: 'Desativar ordenação',
  columnMenuSortAsc: 'Ordem ascendente',
  columnMenuSortDesc: 'Ordem descendente',

  // Cabeçalho da coluna
  columnHeaderFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,
  columnHeaderFiltersLabel: 'Mostrar filtros',
  columnHeaderSortIconLabel: 'Ordenar',

  // Linhas selecionadas
  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} resultados selecionados`
      : `${count.toLocaleString()} resultado selecionado`,

  // Numero total de linhas
  footerTotalRows: 'Total de resultados:',

  // Total de linhas visiveis
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,

  // Seleção de Checkbox
  checkboxSelectionHeaderName: 'Seleção de Checkbox',
  checkboxSelectionSelectAllRows: 'Selecionar todos os resultados',
  checkboxSelectionUnselectAllRows: 'Deselecionar todos os resultados',
  checkboxSelectionSelectRow: 'Selecionar',
  checkboxSelectionUnselectRow: 'Deselecionar',

  // Célula booleana
  booleanCellTrueLabel: 'Sim',
  booleanCellFalseLabel: 'Não',

  // Célula de ações
  actionsCellMore: 'mais',

  // Fixação de colunas
  pinToLeft: 'Fixar à esquerda',
  pinToRight: 'Fixar à direita',
  unpin: 'Desafixar',

  // Árvore de dados
  treeDataGroupingHeaderName: 'Grupo',
  treeDataExpand: 'expandir',
  treeDataCollapse: 'esconder',

  // Agrupamento de colunas
  groupingColumnHeaderName: 'Grupo',
  groupColumn: (name) => `Agrupar por ${name}`,
  unGroupColumn: (name) => `Parar de agrupar por ${name}`,

  // Master/detail ("Mestre"/Detalhes)
  expandDetailPanel: 'Expandir',
  collapseDetailPanel: 'Esconder',

  // Used core components translation keys
  MuiTablePagination: {
    labelDisplayedRows: (info) =>
      `${info.from}-${info.to} de ${info.count}`,
    labelRowsPerPage: 'Resultados por página:',
    rowsPerPageOptions: [25, 50, 75, 100],
    backIconButtonProps: { title: 'Página anterior' },
    nextIconButtonProps: { title: 'Próxima página' },
  },

  // Ordenação de linhas
  rowReorderingHeaderName: 'Reordenação de resultados',
};
