import styled from 'styled-components';

interface PropsCaixaFlutuante {
  visivel: boolean;
  carregandoDados: boolean;
}

export const CaixaFlutuante = styled.div<PropsCaixaFlutuante>`
  && {
    display: ${props => props.visivel ? 'block' : 'none'};
    max-width: 200px;
    ${props => (
    props.carregandoDados
      ? 'width: 150px; height: 175px;'
      : ''
  )}

    position: absolute;
    top: 20px;
    right: 20px;
    
    padding: 0;
    background-color: #fff;
    box-shadow: 0 0 12px #000;
    border-radius: 8px;
  }
`;

export const BarraSuperior = styled.div`
  && {
    display: flex;
    justify-content: flex-end;
    padding: 8px 0;
    height: 20px;
  }
`;

export const Conteudo = styled.div`
  && {
    display: flex;
    flex-flow: column nowrap;

    &.DialogoDeDetalhes-corpo {
      align-items: center;
      padding: 0 12px 12px;
    }

    &.DialogoDeDetalhes-conteudo {
      width: 100%;
      padding: 0 0 12px;
      
      &.DetalhesPessoas {
        & > * {
          padding: 3px 0;
        }
      }
    }
  }
`;
