import React, {
  memo,
  useMemo,
} from 'react';

import { MarcaDeCor } from './estilos';

import { ArmazenamentoLocal } from '../../../../../../../../../../util';

import type { DadosCor } from '../../../../../../../../../../util/tipos';

interface PropsCorPessoa {
  idCor: number;
}

function CorPessoa(props: PropsCorPessoa) {
  /**
   * Memo que armazena a cor a ser usada para uma pessoa
   */
  const cor = useMemo<DadosCor>(() => {
    const armazenamento = new ArmazenamentoLocal<DadosCor[]>('cores');
    const cores = armazenamento.existe() ? armazenamento.obter() : [];
    return cores.find(item => item.idCor === props.idCor)
      ?? { hexa: '#fff', idCor: -1, nome: 'Sem cor' } as DadosCor; // objeto failsafe
  }, [props.idCor]);

  return <MarcaDeCor hexa={cor.hexa}>{cor.nome}</MarcaDeCor>;
}

/**
 * Componente de cor para coluna de Cor em `<GradeDePessoas>`
 */
export default memo(CorPessoa);
